import React from 'react'
import PropTypes from 'prop-types'

class Image extends React.Component {
    render () {
        return <>
            {
                this.props.lazy ?
                    this.props.srcWebp ?
                        <>
                            <picture>
                                <source data-srcSet={this.props.srcWebp} type='image/webp'/>
                                <img 
                                    height={this.props.height} 
                                    width={this.props.width} 
                                    className={`lazyload ${this.props.className}`}
                                    alt={this.props.alt}
                                    data-src={this.props.src}
                                    loading={'lazy'} />
                            </picture>
                            <noscript>
                                <img 
                                    height={this.props.height} 
                                    width={this.props.width} 
                                    className={`${this.props.className}`}
                                    alt={this.props.alt}
                                    src={this.props.src}
                                    loading={'lazy'} />
                            </noscript>
                        </>
                        :
                        <>
                            <img 
                                height={this.props.height} 
                                width={this.props.width} 
                                className={`lazyload ${this.props.className}`}
                                alt={this.props.alt}
                                data-src={this.props.src}
                                loading={'lazy'} />
                            <noscript>
                                <img 
                                    height={this.props.height} 
                                    width={this.props.width} 
                                    className={`${this.props.className}`}
                                    alt={this.props.alt}
                                    src={this.props.src}
                                    loading={'lazy'} />
                            </noscript>
                        </>:
                    <img 
                        height={this.props.height} 
                        width={this.props.width} 
                        className={`${this.props.className}`}
                        alt={this.props.alt}
                        src={this.props.src}
                        loading={'lazy'} />
                
            }
        </>
    }
}

Image.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    lazy: PropTypes.bool, 
    linkClasses: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.string,   
    srcWebp: PropTypes.string
}

Image.defaultProps = {
    lazy: true,
    className: 'mt-4' 
}

export default Image