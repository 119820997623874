import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

// utils
import {SLACK_ENDPOINT, SLACK_PAYLOAD, SEGMENT_IDENTIFY, SEGMENT_EVENT} from '../../utils/constants'

const formValues = {
    name: '',
    email: '',
    phone: '',
    message: ''
}

const formSchema = Yup.object().shape({
    name: Yup.string()
        .required('Enter your name so we know who you are'),

    email: Yup.string()
        .email('Check your email address. Did you add @?')
        .required('Enter your email so we can get in touch with you'),

    message: Yup.string()
        .min(2, 'Enter a message, so we know what you want')
        .required('Let us know what you\'re wanting to contact us about')
})

const requiredAsterix = <span className='text-danger pr-1'>*</span>

class ContactForm extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showNotification: false 
        }
    }

    submitForm(values, body){
        /** SLACK WEBHOOKS: https://stackoverflow.com/questions/45752537/slack-incoming-webhook-request-header-field-content-type-is-not-allowed-by-acce **/
        /** Tldr: Make sure there's no header as it breaks via JS. Alternatives is using header: application/x-www-form-urlencoded **/
        /** Slack payload will break unless it is wrapped within another object **/

        SEGMENT_IDENTIFY(values)
        SEGMENT_EVENT(values, 'contact_form_sent')

        fetch(SLACK_ENDPOINT, {
            method: 'POST',
            body: body ? body : JSON.stringify(SLACK_PAYLOAD(values))
        }).catch(console.error)
    }

    render(){
        return  <>
            <Formik initialValues={formValues} validationSchema={formSchema} onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    this.submitForm(values)
                    setSubmitting(false)
                    resetForm()
                }, 400)
            }}
            >
                {({ values,errors,touched,handleChange,handleBlur, isSubmitting }) => (
                    <Form className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='name'>{requiredAsterix}Your name</label>
                            <Field className={`form-control ${ errors.name && touched.name ? 'is-invalid' : ''} ${!errors.name && touched.name ? 'is-valid' : ''}`}  type='text' name='name' disabled={isSubmitting}/>
                            {errors.name && touched.name && (<small className='form-text text-danger'>{errors.name}</small>)}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='email'>{requiredAsterix}Email</label>
                            <Field className={`form-control ${ errors.email && touched.email ? 'is-invalid' : ''} ${!errors.email && touched.email ? 'is-valid' : ''}`} type='email' name='email' disabled={isSubmitting}/>
                            {errors.email && touched.email && (<small className='form-text text-danger'>{errors.email}</small>)}
                        </div>
                        <div>
                            <label htmlFor='message'>{requiredAsterix}Message</label>
                            <Field name='message' as='textarea' spellcheck={true} rows={4} disabled={isSubmitting} className='form-control'onChange={handleChange} onBlur={handleBlur} value={values.message} />
                            { errors.message && touched.message && <small className='form-text text-danger'>{errors.message}</small>}
                        </div>
                        <hr />
                        <button className='btn btn-primary mb-3 px-5' type='submit' disabled={isSubmitting}>
                            {this.state.booking ? 'Book a call' : 'Send message'}
                        </button>
                    </Form>
                )}   
            </Formik>
            {
                this.state.showNotification &&
                <div className='shadow-2 py-2 px-3 rounded bg-white'>
                    <p className='m-0'><small>{this.state.notificationMessage}</small></p> 
                </div> 
            }
        </>
    }
}

export default ContactForm